import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #ff7eb3;
  transition: color 0.3s;

  &:hover {
    color: #ff4b5c;
  }
`;

const GifImage = styled.img`
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        <Header>น่ารักที่สุด!!! ❤️</Header>
        <GifImage src="https://gifdb.com/images/high/cute-thank-you-couple-cats-m3lmmb74ihbvj8kt.gif" alt="cute thank you couple cats" />
      </ModalContent>
    </ModalOverlay>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 400px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 50px auto;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 480px) {
    padding: 40px;
  }
`;

const Header = styled.h1`
  font-family: 'Kanit', sans-serif;
  font-size: 32px;
  color: #ff005c; // สีชมพูสดใส
  margin-bottom: 15px;

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const Message = styled.p`
  font-family: 'Kanit', sans-serif;
  font-size: 24px;
  margin-top: 15px;
  color: #333333; // สีเทาเข้ม

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Button = styled.button`
  margin-top: 25px;
  padding: 18px 35px;
  font-family: 'Kanit', sans-serif;
  font-size: ${(props) => props.fontSize || 18}px;
  color: white;
  background: linear-gradient(45deg, #ff6a00, #ee0979); // Gradient สีส้มสดใสและสีชมพูเข้ม
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;

  &:hover {
    background: linear-gradient(45deg, #ee0979, #ff6a00);
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 480px) {
    padding: 15px 30px;
    font-size: ${(props) => props.fontSize || 16}px;
  }
`;

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [acceptButtonSize, setAcceptButtonSize] = useState(18);
  const [rejectButtonSize, setRejectButtonSize] = useState(18);

  const handleAcceptClick = () => {
    setShowModal(true);
    setShowConfirm(false);
  };

  const handleRejectClick = () => {
    setShowConfirm(true);
    setAcceptButtonSize(acceptButtonSize + 2);
    setRejectButtonSize(rejectButtonSize - 2);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <marquee style={{ fontSize: '32px', fontWeight: 500, color: '#ff005c' }}>ง้อคุณแฟน 🥺</marquee>
      <Message>ง้อค้าบบบ ดีกันได้ไหม</Message>
      {showConfirm && <Message>แน่ใจหรอ</Message>}
      <Button fontSize={acceptButtonSize} onClick={handleAcceptClick}>
        ก็ได้ ดีกัน
      </Button>
      <Button fontSize={rejectButtonSize} onClick={handleRejectClick}>
        ไม่
      </Button>
      <GifImage src='https://gifdb.com/images/high/stitch-sad-lonely-drawing-9ni50b7z87q6xkit.webp' alt='awwww' style={{ marginTop: '15px', width: '90%'}}/>
      <Modal isOpen={showModal} onClose={closeModal} />
    </Container>
  );
};

export default HomePage;
